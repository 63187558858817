<template>
    <div class="card">
        <div class="card">
            <div class="card-body text-center">
                <h1>Vendas por quantidade</h1>
            </div>
        </div>
        <div class="accordion" role="tablist">
            <form role="form" class="form-horizontal">
                <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button block v-b-toggle.pesquisar variant="light">Como deseja Producar:</b-button>
                    </b-card-header>
                    <b-collapse id="pesquisar" visible accordion="pesquisar" role="tabpanel">
                        <b-card-body>
                            <div class="form-row form-group" role="group">
                                <div class="col-sm-12 col-md-12" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">Modelo do Documento:</label>
                                        <div class="col">
                                            <input type="text" class="form-control" placeholder="digite">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">produtos:</label>
                                        <div class="col">
                                            <Multiselect :options="produtos" label="produto_nome">

                                            </Multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row form-group" role="group">
                                <div class="col-sm-12 col-md-4" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">Ordem dos produtos:</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo11" name="ativo" ref="cheque_status">Código
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo11" name="ativo" ref="cheque_status">Nome
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">&nbsp;</label>
                                        <div class="col">
                                            <b-form-checkbox id="produto-ativo11" name="ativo"
                                                ref="cheque_status">Quantidade
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row form-group" role="group">
                                <div class="col-sm-12 col-md-6" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">De:</label>
                                        <div class="col">
                                            <input id="searchCompras-fromData" type="datetime-local" class="form-control"
                                                placeholder="dd/mm/aaaa hh:mm" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6" id="desc_ID">
                                    <div class="form-group">
                                        <label for="cartao_in_id" class="col-form-label">Até:</label>
                                        <div class="col">
                                            <input id="searchCompras-fromData" type="datetime-local" class="form-control"
                                                placeholder="dd/mm/aaaa hh:mm" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="card">
                                <div class="card-body">
                                    <b-table striped hover :items="list_produtos" responsive id="sangria-itens"
                                        :fields="fields">
                                    </b-table>
                                </div>
                            </div> -->
                            <!-- <div class="col-sm-12 col-md-3" id="desc_ID">
                                <div class="form-group">
                                    <label for="cheque_in_id" class="col-form-label">Relatorio por Grupo</label>
                                    <div class="col">
                                        <b-form-checkbox  id="produto-ativo11" name="ativo"
                                            ref="cheque_status">
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div> -->
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </form>
        </div>
        <!--Engloba todos -->
        <div class="card-body" id="top">
            <div class="col-md-12 mt-10">
                <button class="btn btn-light" id="butt">
                    Visualizar
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import { http } from "../../../../../helpers/easyindustriaapi/config";
export default {
    data() {
        return {
            nome: 'ola',
            hideSearches: false,
            produtos:[],
            centtros: [
                'centros1',
                'centros2',
                'centros3',
            ],
            fornecedores: [
                'fornecedor1',
                'fornecedor2',
                'fornecedor3',
            ],
            fields: [
                { label: "ID", key: "id", tdClass: 'text-left', thClass: 'text-left' },
                { label: "Código de Barras", key: "cod_barras", tdClass: 'text-left', thClass: 'text-left' },
                { label: "Grupos", key: "grupo", tdClass: 'text-left', thClass: 'text-left' },
                { label: "Unidade", key: "um", tdClass: 'text-left', thClass: 'text-left' },
                { label: "Estoque", key: "estoque", tdClass: 'text-left', thClass: 'text-left' },
                { label: "Atacado", key: "atacado", tdClass: 'text-left', thClass: 'text-left' },
                { label: "Custo", key: "custo", tdClass: 'text-left', thClass: 'text-left' },
                { label: "Preço", key: "preco", tdClass: 'text-left', thClass: 'text-left' },
                { label: "NCM", key: "ncm", tdClass: 'text-left', thClass: 'text-left' },
                { label: "Nome", key: "nome", tdClass: 'text-', thClass: 'text-left' },
                { label: "Descricão", key: "descricao", tdClass: 'text-left', thClass: 'text-left' },
                // { label: "Valor", key: "valor", tdClass: 'text-right', thClass: 'text-center', formatter: "formatterCurrBR" },
                // { label: "Criado", key: "data_criacao", tdClass: 'text-right', thClass: 'text-center' },
                // { label: "Ações", key: "excluir", tdClass: 'text-center', thClass: 'text-center' },
            ],
            list_produtos: [
                {
                    id: 1,
                    cod_barras: '12344',
                    grupo: 'grupo1',
                    um: 'DM',
                    estoque: '2',
                    atacado: 12.00,
                    custo: 12.00,
                    preco: 12.00,
                    ncm: '1234',
                    nome: "manga",
                    descricao: "Manga"
                },
                {
                    id: 2,
                    cod_barras: '12344',
                    grupo: 'grupo1',
                    um: 'DM',
                    estoque: '2',
                    atacado: 12.00,
                    custo: 12.00,
                    preco: 12.00,
                    ncm: '1234',
                    nome: "Pera",
                    descricao: "Pera"
                },
                {
                    id: 3,
                    cod_barras: '12344',
                    grupo: 'grupo1',
                    um: 'DM',
                    estoque: '2',
                    atacado: 12.00,
                    custo: 12.00,
                    preco: 12.00,
                    ncm: '1234',
                    nome: "Uva",
                    descricao: "Uva"
                }
            ]
        }
    },
    components: { Multiselect },
    computed: {
        isHide() {
            return (!this.inserindo && !this.editando);
        },
    }, 
    created() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
        //this.notification.clear;
    },
    mounted() {
        this.get_produtos()
    },
    methods: {
        // geters para preencher os vetotes do multiselct 
        get_fornecedores() {

        },
        get_centro_resultados() {

        },
        get_centro_custo() {

        },
        // -----------------------------------------
        doPost() {

        },
        async get_produtos() {
            try {
                await http.get('/produto?empresa_id=' + this.currentEmpresa.id)
                    .then(res => {
                        this.produtos = res.data ? res.data : null;
                        console.log(this.produtos)
                    })
                    
            } catch (error) {
                //this.offLoader();
                if (error.response && error.response.status === 403) {
                    this.error('Usuário não tem permissão!');
                }
            }
        },
        searchInputs() {
            this.hideSearches = !this.hideSearches;
        },
    }

}
</script>
  
<style></style>